import { lighten } from 'polished';
import styled, { css, keyframes } from 'styled-components';
import tinycolor from 'tinycolor2';

interface ProductItemListProps {
  readOnlyMenu: boolean;
  isTitleGrand: boolean;
}

const fadeInBottom = keyframes`
  ${'0%'} {
    opacity: 0;
    margin-top: 10rem;
  }
  ${'100%'} {
    opacity: 1;
    margin-top: 0;
  }
`;

export const AdultProductIcon = styled.div`
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  color: red;
`;

export const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 28rem;
  background-color: ${({ theme }) => theme && theme.colors.Background};
  justify-items: center;
  align-items: flex-start;
  height: 100%;
  animation: ${fadeInBottom} 1s;
  list-style-type: none;

  @media (orientation: landscape) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (min-width: 1450px) {
    grid-template-columns: repeat(8, 1fr);
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Item = styled.li<ProductItemListProps>`
  width: 100%;
  height: 28rem;
  max-width: 25rem;
  align-items: center;
  justify-content: center;
  padding: 1.25rem;
  grid-column: span 2;

  &:nth-last-child(1):nth-child(odd) {
    grid-column: span 2;
  }

  button {
    width: 100%;
    height: 100%;
    flex: 1;
    border: 0;
    background-color: ${(props) => props.theme.colors.Background};
    padding: 1.25rem;
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid
      ${(props) =>
        tinycolor(props.theme.colors.Background).isLight()
          ? '#dbdbdb'
          : '#332F2E'};
    border-radius: 8%;

    ${({ readOnlyMenu }) =>
      !readOnlyMenu &&
      css`
        &:disabled {
          filter: brightness(0.6);
        }
      `};

    aside {
      width: 100%;
      height: 10rem;
      border-radius: 0.25rem;

      img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 0.5rem;
      }
    }

    & > div {
      width: 100%;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      padding: 1rem 0 0.5rem;

      strong {
        font-family: 'Poppins';
        overflow: hidden;
        text-align: start;
        font-size: 1.5rem; //1.75
        line-height: 1.75rem; //2
        color: ${(props) =>
          tinycolor(props.theme.colors.Background).isLight()
            ? '#262626'
            : lighten(0.8, '#262626')};
        ${({ isTitleGrand }) => !isTitleGrand && `max-height: 68px;`};
      }

      & > span {
        font-family: 'Poppins';
        display: block;
        font-size: 1.3rem;
        line-height: 1.3rem;
        color: ${(props) =>
          tinycolor(props.theme.colors.Background).isLight()
            ? '#262626'
            : lighten(0.6, '#262626')};
        line-clamp: 3;
        -webkit-line-clamp: 3; /* no of lines */
        -webkit-box-orient: horizontal;
        text-overflow: ellipsis;
        white-space: normal;
        max-height: 4rem;
        overflow: hidden;
        text-align: start;
      }

      div {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
        margin-bottom: -0.625rem;

        small {
          font-family: Poppins;
          font-size: 1rem;
          margin-right: 0.125rem;
          color: ${(props) =>
            tinycolor(props.theme.colors.Background).isLight()
              ? '#262626'
              : lighten(0.8, '#262626')};
        }

        span {
          font-family: Poppins;
          margin-left: auto;
          font-size: 2rem;
          color: ${(props) =>
            tinycolor(props.theme.colors.Background).isLight()
              ? '#262626'
              : lighten(0.8, '#262626')};
        }

        .valores {
          display: flex;
          flex-direction: column-reverse;
        }

        .valorPromocional {
          font-size: 1.8rem;
        }

        .preco {
          font-size: 1rem;
          text-decoration: line-through;
          color: #dbdbdb;
        }
      }
    }

    #indisponivel {
      display: none;
    }

    :disabled {
      #indisponivel {
        position: absolute;
        display: flex;
        align-items: center;
        top: 1.2rem;
        left: -1.25rem;
        rotate: -35deg;

        & > div {
          display: flex;
          align-items: center;

          color: #fff;
          background: #bf0426;

          border-radius: 0.25rem;
          padding: 0.5rem 0.5rem 0.3rem;

          font-size: 1rem;
          font-weight: bold;
        }
      }
    }
  }
`;
