import { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Loading } from '../components/Loading';
import ModalConnection from '../components/ModalConnetion';
import { ModalMensage } from '../components/ModalMensage';
import { Offline } from '../components/Offline';
import { CheckoutProvider } from '../contexts/CheckoutContext';
import { CompanyContext } from '../contexts/CompanyContext';
import { MenuListProvider } from '../contexts/MenuListContext';
import { ModalProvider } from '../contexts/ModalContext';
import { ModalReviewProvider } from '../contexts/ModalReviewContext';
import { NumberProvider } from '../contexts/NumberContext';
import { PaymentProvider } from '../contexts/PaymentContext';
import { ProductProvider } from '../contexts/ProductContext';
import { SubgroupProvider } from '../contexts/SubgroupContext';
import { ChooseDelivery } from '../pages/ChooseDelivery';
import { ChoosePaymentMethod } from '../pages/ChoosePaymentMethod';
import { ChooseStore } from '../pages/ChooseStore';
import { CpfNF } from '../pages/CpfNf';
import Main from '../pages/Main';
import { MensageTefDestaxa } from '../pages/MensageTefDestaxa';
import Menu from '../pages/Menu';
import { OrderConfirmation } from '../pages/OrderConfirmation';
import { OrderConfirmed } from '../pages/OrderConfirmed';
import { OrderSucceeded } from '../pages/OrderSucceeded';
import { Maintenance } from '../pages/Maintenance';

export function Routes(): JSX.Element {
  const { isLoading, isMsgBox, isOpenMsgBox, linkLoading } =
    useContext(CompanyContext);

  return (
    <Switch>
      <MenuListProvider>
        <SubgroupProvider>
          <ProductProvider>
            <ModalProvider>
              <NumberProvider>
                <CheckoutProvider>
                  <PaymentProvider>
                    <ModalReviewProvider>
                      <Offline>
                        <ModalConnection />
                      </Offline>
                      <Loading isOpen={isLoading} isLink={linkLoading} />
                      <ModalMensage isOpen={isOpenMsgBox} message={isMsgBox} />
                      <Route path="/" exact component={Main} />
                      <Route path="/stores" exact component={ChooseStore} />
                      <Route path="/menu" exact component={Menu} />
                      <Route
                        path="/maintenance"
                        exact
                        component={Maintenance}
                      />
                      <Route
                        path="/chooseDelivery"
                        exact
                        component={ChooseDelivery}
                      />
                      <Route
                        path="/payment"
                        exact
                        component={ChoosePaymentMethod}
                      />
                      <Route
                        path="/tefdestaxa"
                        exact
                        component={MensageTefDestaxa}
                      />
                      <Route
                        path="/orderConfirmation"
                        exact
                        component={OrderConfirmation}
                      />
                      <Route path="/orderNF" exact component={CpfNF} />
                      <Route
                        path="/confirmationCode"
                        exact
                        component={OrderConfirmed}
                      />
                      <Route path="/success" exact component={OrderSucceeded} />
                    </ModalReviewProvider>
                  </PaymentProvider>
                </CheckoutProvider>
              </NumberProvider>
            </ModalProvider>
          </ProductProvider>
        </SubgroupProvider>
      </MenuListProvider>
    </Switch>
  );
}
