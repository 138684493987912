/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useContext, useEffect, useState } from 'react';

import { FaPen } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container } from './styles';

import EmptyImage from '../../../assets/empty-product.png';
import { ModalConfirm } from '../../../components/ModalConfirm';
import { NumberFormat } from '../../../components/NumberFormat';
import { CheckoutContext } from '../../../contexts/CheckoutContext';
import { CompanyContext } from '../../../contexts/CompanyContext';
import { ModalContext } from '../../../contexts/ModalContext';
import { ModalReviewContext } from '../../../contexts/ModalReviewContext';

export function Checkout(): JSX.Element {
  const { setVisivelCar, visivelCar } = useContext(CompanyContext);
  const { selectOrderItem, openCartCredit, setOpenCartCredit } =
    useContext(ModalContext);
  const { orderItems, orderPrice, oldOrderPrice, setNewOrderItems } =
    useContext(CheckoutContext);
  const { changeIsModalReviewVisible } = useContext(ModalReviewContext);
  const history = useHistory();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(0);

  function handleCancelOrder() {
    history.push('/');
  }

  function handleSelectItem(id: number) {
    setIsModalVisible(true);
    setSelectedOrderId(id);
  }

  function handleConfirmDelete() {
    const produto = orderItems.find((x) => x.id === selectedOrderId);
    setNewOrderItems(orderItems.filter((item) => item.id !== selectedOrderId));
    toast.success(
      `Produto${
        produto?.name ? ` ${produto?.name}` : ''
      } removido com sucesso!`,
    );
    setIsModalVisible(false);
  }

  useEffect(() => {
    if (openCartCredit) {
      setVisivelCar(!visivelCar);
      setOpenCartCredit(false);
    }
  }, [openCartCredit]);

  return (
    <>
      {isModalVisible && (
        <ModalConfirm
          title="Confirmar"
          description="Deseja realmente remover esse item do carrinho?"
          onCancel={() => setIsModalVisible(false)}
          onConfirm={() => handleConfirmDelete()}
        />
      )}
      <Container visivelCart={visivelCar}>
        <header onClick={() => setVisivelCar(!visivelCar)}>
          <span>Carrinho</span>
          <div className="prices">
            <NumberFormat
              prefix="R$"
              className="oldPrices"
              hidden={!oldOrderPrice}
            >
              {oldOrderPrice}
            </NumberFormat>
            <NumberFormat prefix="R$">{orderPrice}</NumberFormat>
          </div>
        </header>
        <div>
          <ul>
            {orderItems.map((item) => (
              <li key={item.id}>
                <div>
                  <span>{`${item.quantity}x`}</span>
                  <aside>
                    <img
                      src={item.image === '' ? EmptyImage : item.image}
                      alt="product-cart"
                    />
                  </aside>
                  <div>
                    <span
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'elipsis',
                        whiteSpace: 'normal',
                        lineHeight: '2rem',
                      }}
                    >
                      <div className="mainArea">
                        <h1>{item.name}</h1>
                        <div className="subItemArea">
                          {item.subItems.slice(0, 2).map((subItem) => (
                            <div
                              key={subItem.selectedSubItemId}
                              className="subItem"
                            >
                              {!subItem.isSingleOption && (
                                <small>
                                  {`${subItem.quantity / item.quantity}x`}
                                </small>
                              )}
                              <small>{subItem.name}</small>
                              {subItem.unitPrice !== 0 && (
                                <span>
                                  <NumberFormat prefix="(+R$" suffix=")">
                                    {subItem.unitPrice}
                                  </NumberFormat>
                                </span>
                              )}
                            </div>
                          ))}
                          {item.subItems.length > 2 && (
                            <p
                              style={{ marginTop: '-0.75rem', height: '1rem' }}
                            >
                              ...
                            </p>
                          )}
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
                <div className="editPriceSide">
                  <NumberFormat prefix="R$" className="finalValue">
                    {item.total}
                  </NumberFormat>
                  <div className="buttonEdit">
                    <button
                      type="button"
                      onClick={() => selectOrderItem(item)}
                      className="button"
                    >
                      <FaPen />
                    </button>
                    <button
                      type="button"
                      onClick={() => handleSelectItem(item.id)}
                      className="button"
                    >
                      <IoMdClose />
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <div className="divButton">
            <button type="button" onClick={handleCancelOrder}>
              Cancelar
            </button>
            <button
              type="button"
              disabled={orderItems.length === 0}
              onClick={() => changeIsModalReviewVisible(true)}
            >
              Finalizar
            </button>
          </div>
        </div>
      </Container>
    </>
  );
}
