import { useContext, useEffect, useState } from 'react';
import { IoArrowBackCircle } from 'react-icons/io5';
import { RiDeleteBack2Fill } from 'react-icons/ri';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import InputMask from 'react-input-mask';
import { CompanyContext } from '../../contexts/CompanyContext';
import { Content, Form } from './styles';
import { CheckoutContext } from '../../contexts/CheckoutContext';
import { NumberContext } from '../../contexts/NumberContext';

export function CpfNF(): JSX.Element {
  const [show, setShow] = useState(false);

  const {
    needPayment,
    setIsLoading,
    isLoading,
    serviceElectron,
    loadPaymentMethods,
  } = useContext(CompanyContext);
  const { lastOrder, createOrder } = useContext(CheckoutContext);

  const {
    addIdCpfCode,
    setCpf,
    messageErrorCpf,
    setEmail,
    cpf,
    confirmCPFModal,
    setMessageErrorCpf,
  } = useContext(NumberContext);

  const history = useHistory();

  useEffect(() => {
    if (Object.keys(lastOrder).length !== 0) history.push('/success');
  }, [lastOrder]);

  const action = async () => {
    /*   if (selectedCompany.solicitaTelefone && !needPayment) {
      setIsLoading(false);
      history.push('/orderConfirmation');
    } else  */
    if (needPayment) {
      await loadPaymentMethods();
      setIsLoading(false);
      history.push('/payment');
    } else {
      try {
        await createOrder(0);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  async function validation() {
    setIsLoading(true);
    if (confirmCPFModal()) action();
    else setIsLoading(false);
  }

  const getMask = (e) => {
    const value = e.replace(/\D/g, '');

    return value.length <= 11
      ? value
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d{1,2})$/, '$1-$2')
      : value
          .replace(/^(\d{2})(\d)/, '$1.$2')
          .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
          .replace(/\.(\d{3})(\d)/, '.$1/$2')
          .replace(/(\d{4})(\d)/, '$1-$2');
  };

  return (
    <Content>
      <button type="button" onClick={() => history.goBack()}>
        <IoArrowBackCircle />
      </button>
      <div>
        <div>
          <h1>Informe seu CPF para emissão do documento físcal</h1>
          <Form
            hasError={!!messageErrorCpf}
            style={{
              padding: '0px',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <input
              id="cpfInput"
              type={show ? 'text' : 'password'}
              value={getMask(cpf)}
              onChange={(e) => setCpf(e.target.value.replace(/\D/g, ''))}
              placeholder="CPF"
              maxLength={18}
            />
            <button
              type="button"
              onClick={() => setShow(!show)}
              style={{
                margin: '0px',
                padding: '0px',
                border: '0px',
                height: '100%',
              }}
            >
              {!show ? (
                <VscEyeClosed className="eye-color" size={71} />
              ) : (
                <VscEye className="eye-color" size={71} />
              )}
            </button>
          </Form>
          <p className="errorMessage">{messageErrorCpf}</p>
          {/* <form
            style={{
              padding: '0px',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <input
              value={email}
              onFocus={() => setIsKeyboard(true)}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
          </form> */}
          {serviceElectron.isElectronApp && (
            <div aria-disabled={!serviceElectron.isElectronApp}>
              <section>
                <button type="button" onClick={() => addIdCpfCode('7')}>
                  7
                </button>
                <button type="button" onClick={() => addIdCpfCode('8')}>
                  8
                </button>
                <button type="button" onClick={() => addIdCpfCode('9')}>
                  9
                </button>
              </section>
              <section>
                <button type="button" onClick={() => addIdCpfCode('4')}>
                  4
                </button>
                <button type="button" onClick={() => addIdCpfCode('5')}>
                  5
                </button>
                <button type="button" onClick={() => addIdCpfCode('6')}>
                  6
                </button>
              </section>
              <section>
                <button type="button" onClick={() => addIdCpfCode('1')}>
                  1
                </button>
                <button type="button" onClick={() => addIdCpfCode('2')}>
                  2
                </button>
                <button type="button" onClick={() => addIdCpfCode('3')}>
                  3
                </button>
              </section>
              <section>
                <button type="button" onClick={() => addIdCpfCode('0')}>
                  0
                </button>
                <button type="button" onClick={() => addIdCpfCode('delete')}>
                  <RiDeleteBack2Fill />
                </button>
              </section>
            </div>
          )}

          <footer>
            <button
              type="button"
              onClick={() => {
                setIsLoading(true);
                setCpf('');
                setEmail('');
                setMessageErrorCpf('');
                action();
              }}
            >
              Continuar sem Informar
            </button>
            <button
              className="finish-button"
              type="button"
              disabled={isLoading}
              onClick={() => validation()}
            >
              Continuar
            </button>
          </footer>
        </div>
      </div>
    </Content>
  );
}
