import styled, { css } from 'styled-components';
import tinycolor from 'tinycolor2';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 10;
  background-color: ${({ theme }) =>
    theme && tinycolor(theme.colors.Background).isDark()
      ? 'rgba(0, 0, 0, 0.6)'
      : 'rgba(0, 0, 0, 0.8)'};
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    overflow: auto;
    max-height: 85vh;
    width: 85vw;
    padding: 1rem 2rem;
    border-radius: 10px;
    border: 0;
    background-color: ${({ theme }) => theme && theme.colors.Background};
    animation-name: slide-bottom;
    margin-top: 4rem;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr;

    ${({ theme }) =>
      tinycolor(theme.colors.Background).isDark() &&
      css`
        border: 1px solid;
        border-color: ${theme.colors.SecondaryColor};
      `};

    @media screen and (min-width: 900px) and (max-width: 1366px) {
      max-height: 100%;
      margin-top: 1rem;
    }

    @media screen and (orientation: landscape) {
      width: 35%;
      height: 100%;
    }
  }

  @keyframes slide-bottom {
    0% {
      margin-top: 4rem;
      opacity: 0;
    }
    100% {
      margin-top: 0;
      opacity: 1;
    }
  }
`;
