import { useContext, useEffect, useState } from 'react';
import { IoArrowBackCircle } from 'react-icons/io5';
import { RiDeleteBack2Fill } from 'react-icons/ri';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import InputMask from 'react-input-mask';
import { CheckoutContext } from '../../contexts/CheckoutContext';
import { CompanyContext } from '../../contexts/CompanyContext';
import { NumberContext } from '../../contexts/NumberContext';
import { Content } from './styles';

export function OrderConfirmation(): JSX.Element {
  const [show, setShow] = useState(true);

  const {
    needPayment,
    loadPaymentMethods,
    setOpenIsMsgBox,
    setIsLoading,
    isLoading,
    setIsMsgBox,
    serviceElectron,
  } = useContext(CompanyContext);
  const { lastOrder, createOrder } = useContext(CheckoutContext);
  const { addIdNumber, mobileNumber, confirmMobileNumber, setMobileNumber } =
    useContext(NumberContext);

  const history = useHistory();

  useEffect(() => {
    if (Object.keys(lastOrder).length !== 0) {
      history.push('/success');
    }
  }, [lastOrder]);

  async function sendCode() {
    setIsLoading(true);
    if (confirmMobileNumber()) {
      if (serviceElectron?.isElectronApp)
        await serviceElectron?.ipcRenderer?.invoke(
          'Log',
          `Solicita pagamento ${needPayment}`,
        );
      if (needPayment) {
        await loadPaymentMethods();
        history.push('/payment');
      } else {
        await serviceElectron?.ipcRenderer?.invoke('Log', `not payment`);
        try {
          await createOrder(0);
        } catch (error) {
          await serviceElectron?.ipcRenderer?.invoke('LogError', error);
        } finally {
          if (Object.keys(lastOrder).length !== 0) history.push('/success');
        }
      }
    } else {
      setIsMsgBox('Preencha o número de telefone corretamente');
      setOpenIsMsgBox(true);
    }

    setIsLoading(false);
  }

  return (
    <Content>
      <button type="button" onClick={() => history.goBack()}>
        <IoArrowBackCircle />
      </button>
      <div>
        <div>
          <h1>
            {needPayment
              ? 'Informe seu número de telefone com DDD para receber o status do seu pedido por WhatsApp.'
              : 'Informe seu número de telefone com DDD para confirmar o pedido'}
          </h1>
          <form
            style={{
              padding: '0px',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <InputMask
              id="numberInput"
              type={show ? 'text' : 'password'}
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              mask="(99) 99999-9999"
              maskChar={null}
              placeholder="Número de telefone"
            >
              {(inputProps) => <input {...inputProps} />}
            </InputMask>
            <button
              type="button"
              onClick={() => setShow(!show)}
              style={{
                margin: '0px',
                padding: '0px',
                border: '0px',
                height: '100%',
              }}
            >
              {!show ? (
                <VscEyeClosed className="eye-color" size={71} />
              ) : (
                <VscEye className="eye-color" size={71} />
              )}
            </button>
          </form>
          {serviceElectron.isElectronApp && (
            <div>
              <section>
                <button type="button" onClick={() => addIdNumber('7')}>
                  7
                </button>
                <button type="button" onClick={() => addIdNumber('8')}>
                  8
                </button>
                <button type="button" onClick={() => addIdNumber('9')}>
                  9
                </button>
              </section>
              <section>
                <button type="button" onClick={() => addIdNumber('4')}>
                  4
                </button>
                <button type="button" onClick={() => addIdNumber('5')}>
                  5
                </button>
                <button type="button" onClick={() => addIdNumber('6')}>
                  6
                </button>
              </section>
              <section>
                <button type="button" onClick={() => addIdNumber('1')}>
                  1
                </button>
                <button type="button" onClick={() => addIdNumber('2')}>
                  2
                </button>
                <button type="button" onClick={() => addIdNumber('3')}>
                  3
                </button>
              </section>
              <section>
                <button type="button" onClick={() => addIdNumber('0')}>
                  0
                </button>
                <button type="button" onClick={() => addIdNumber('delete')}>
                  <RiDeleteBack2Fill />
                </button>
              </section>
            </div>
          )}
          <footer>
            <button
              type="button"
              onClick={() => {
                setMobileNumber('');
                // eslint-disable-next-line no-unused-expressions
                needPayment ? history.push('/payment') : history.goBack();
              }}
            >
              {needPayment ? 'Sem informar' : 'Cancelar'}
            </button>
            <button
              className="finish-button"
              type="button"
              disabled={isLoading}
              onClick={() => sendCode()}
            >
              {needPayment ? 'Informado' : 'Continuar'}
            </button>
          </footer>
        </div>
      </div>
    </Content>
  );
}
