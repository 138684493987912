import { Form as Unform } from '@unform/web';
import { darken, lighten } from 'polished';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

export const Form = styled(Unform)`
  background: ${({ theme }) => theme && theme.colors.Background};
  border-radius: 0.5rem;
  padding: 2rem 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: Poppins;
  max-width: 60%;

  @media (orientation: landscape) and (min-width: 1920px) {
    max-width: 20%;
  }

  @media (orientation: landscape) and (min-width: 1024px) {
    max-width: 30%;
  }

  @media (orientation: landscape) and (max-width: 1024px) {
    max-width: 40%;
  }

  h1 {
    font: 2.25rem Poppins;
    margin-bottom: 1rem;
    color: ${({ theme }) =>
      theme && tinycolor(theme.colors.Background).isDark() ? 'white' : 'black'};
  }

  input {
    flex: 1;
    border-radius: 0.5rem;
    border: 2px solid
      ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0.8, theme.colors.Background)
          : lighten(0.8, theme.colors.Background)};
    font-size: 2rem;
    padding: 2rem;
    transition: border 0.2s;

    & + input {
      margin-top: 1rem;
    }

    &::placeholder {
      color: ${({ theme }) => theme && theme.colors.DefaultColor};
    }

    &:focus {
      border: 2px solid ${({ theme }) => theme && theme.colors.DefaultColor};
    }

    &:focus::placeholder {
      color: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0.8, theme.colors.Background)
          : lighten(0.8, theme.colors.Background)};
    }
  }

  button {
    margin-top: 2rem;
    border: 0;
    border-radius: 0.5rem;
    padding: 2rem;

    font: 3rem Poppins;
    color: ${({ theme }) =>
      theme && tinycolor(theme.colors.DefaultColor).isLight()
        ? darken(0.8, theme.colors.Background)
        : lighten(0.8, theme.colors.Background)};

    background: ${({ theme }) => theme && theme.colors.DefaultColor};
  }
`;
