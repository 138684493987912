/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  // eslint-disable-next-line prettier/prettier
  useState
} from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IDataDesconto } from '../pages/Menu/Modais/ModalDiscont';
import api from '../services/api';
import { CompanyContext } from './CompanyContext';
import { ModalContext } from './ModalContext';
import { NumberContext } from './NumberContext';
import { ProductContext } from './ProductContext';

interface CheckoutContextProps {
  lastOrder: LastOrder;
  orderItems: OrderItem[];
  orderPrice: number;
  oldOrderPrice: number;
  setNewOrderItems: (orderItems: OrderItem[]) => void;
  setNewLastOrder: (lastOrder: LastOrder) => void;
  createOrder: (idFormaPagto: number) => Promise<void>;
  setConnection: (connection: WebSocket | undefined) => void;
  setTypeDelivery: (typeDeliverySelected: number) => void;
  connection: WebSocket | undefined;
  typeDelivery: number;
}

interface CheckoutProviderProps {
  children: ReactNode;
}

export interface OrderItem {
  id: number;
  isCredit: boolean;
  isObservation: boolean;
  observation: string;
  productId: number;
  tagRfid: string;
  document: string;
  image: string;
  name: string;
  description: string;
  quantity: number;
  unitPrice: number;
  oldUnitPrice: number;
  total: number;
  ativo: boolean;
  grupoId: number;
  subGrupoId: number;
  subItems: OrderSubItem[];
}

interface OrderSubItem {
  id: number;
  questionId: number;
  selectedSubItemId: number;
  name: string;
  quantity: number;
  unitPrice: number;
  oldUnitPrice: number;
  isSingleOption: boolean;
}

export interface LastOrder {
  idPedido: string;
  numeroIdentificador: string;
  dataPedido: string;
  desconto: number;
  observacao: string;
  total: number;
  urlPagamentoPIX?: string;
}

export const CheckoutContext = createContext({} as CheckoutContextProps);

export function CheckoutProvider({ children }: CheckoutProviderProps) {
  const [orderItems, setOrderItems] = useState<OrderItem[]>([]);
  const [orderPrice, setOrderPrice] = useState(0);
  const [oldOrderPrice, setOldOrderPrice] = useState(0);
  const [lastOrder, setLastOrder] = useState<LastOrder>({} as LastOrder);
  const [typeDelivery, setTypeDelivery] = useState(0);
  const [connection, setConnection] = useState<WebSocket | undefined>(
    undefined,
  );
  const {
    mobileNumber,
    setMobileNumber,
    cpf,
    email,
    setEmail,
    setCpf,
    setCupom,
    setDataDesconto,
    dataDesconto,
  } = useContext(NumberContext);

  const { selectedProduct } = useContext(ProductContext);
  const { isOrderFinalized, selectedCompany, setIsMsgBox, setOpenIsMsgBox } =
    useContext(CompanyContext);

  const {
    questions,
    finalCount,
    finalPrice,
    tryAddToCart,
    tryUpdateCart,
    closeModal,
    documentNumber,
    rfid,
    selectedItem,
    observation,
  } = useContext(ModalContext);

  useEffect(() => {
    if (!tryAddToCart && !tryUpdateCart) return;

    let name = selectedProduct.nome;
    let description = selectedProduct.descricao;
    let productId = selectedProduct.idProduto;
    let unitPrice = !selectedProduct.valorPromocional
      ? selectedProduct.preco
      : selectedProduct.valorPromocional;
    let oldUnitPrice = !selectedProduct.preco
      ? selectedProduct.valorPromocional
      : selectedProduct.preco;
    let image = selectedProduct.imagem;
    let isCredit = selectedProduct.geraValorEmCredito;
    let isObservation = selectedProduct.exibirObservacao;
    let { ativo, grupoId, subGrupoId } = selectedProduct;

    if (Object.keys(selectedProduct).length === 0) {
      name = selectedItem.name;
      description = selectedItem.description;
      productId = selectedItem.productId;
      unitPrice = selectedItem.unitPrice;
      oldUnitPrice = selectedItem.unitPrice;
      image = selectedItem.image;
      isCredit = selectedItem.isCredit;
      isObservation = selectedItem.isObservation;
      ativo = selectedItem.ativo;
      grupoId = selectedItem.grupoId;
      subGrupoId = selectedItem.subGrupoId;
    }

    const item = {} as OrderItem;
    item.id = tryAddToCart ? orderItems.length + 1 : selectedItem.id;
    item.name = name;
    item.description = description;
    item.tagRfid = rfid;
    item.document = documentNumber;
    item.isObservation = isObservation;
    item.observation = observation;
    item.productId = productId;
    item.quantity = finalCount;
    item.total = finalCount * (unitPrice + finalPrice);
    item.unitPrice = unitPrice;
    item.oldUnitPrice = oldUnitPrice;
    item.image = image;
    item.isCredit = isCredit;
    item.ativo = ativo;
    item.grupoId = grupoId;
    item.subGrupoId = subGrupoId;
    item.subItems = [];

    questions.forEach((question) => {
      question.pergunta.perguntaItens.forEach((questionItem) => {
        const subItem = {} as OrderSubItem;
        subItem.id = 0;
        if (questionItem.count > 0) {
          subItem.id = item.subItems.length + 1;
          subItem.questionId = question.pergunta.id;
          subItem.selectedSubItemId = questionItem.id;
          subItem.name = questionItem.opcao;
          subItem.quantity = questionItem.count * finalCount;
          subItem.unitPrice = questionItem.preco;
          subItem.isSingleOption = question.pergunta.maximo === 1;

          item.subItems.push(subItem);
        }
      });
    });

    setOrderItems((prev) =>
      tryAddToCart
        ? [...prev, item]
        : prev.map((prevItem) => {
            let newItem = prevItem;
            if (prevItem.id === selectedItem.id) {
              newItem = item;
            }

            return newItem;
          }),
    );
    if (tryAddToCart)
      toast.success(`Produto ${name} adicionado ao carrinho com sucesso!`);
    else toast.success(`Produto ${name} atualizado no carrinho com sucesso!`);
    closeModal();
  }, [
    selectedProduct,
    questions,
    tryAddToCart,
    tryUpdateCart,
    closeModal,
    finalCount,
    finalPrice,
    orderItems,
    documentNumber,
    selectedItem,
  ]);

  useEffect(() => {
    if (orderItems.length === 0) {
      setOrderPrice(0);
      setOldOrderPrice(0);
      return;
    }
    const total = orderItems.map((a) => a.total).reduce((a, b) => a + b);
    setOrderPrice(total);
  }, [orderItems]);

  useEffect(() => {
    if (!isOrderFinalized) return;
    setMobileNumber('');
    setOrderPrice(0);
    setOldOrderPrice(0);
    setOrderItems([]);
    setLastOrder({} as LastOrder);
    setEmail('');
    setCpf('');
    setCupom('');
    setDataDesconto({} as IDataDesconto);
  }, [isOrderFinalized]);

  function setNewOrderItems(newOrderItems: OrderItem[]) {
    setOrderItems(newOrderItems);
  }

  function setNewLastOrder(newLastOrder: LastOrder) {
    setLastOrder(newLastOrder);
  }

  async function createOrder(paymentMethodId: number) {
    const order = {
      idEmpresa: selectedCompany.id,
      chaveSeguranca: btoa(
        `${selectedCompany.id}@IDX@SISTEMAS_${selectedCompany.id}`,
      ),
      formaPagamento: paymentMethodId,
      desconto:
        dataDesconto.tipoValor === 0
          ? orderPrice * (dataDesconto.valorDesconto / 100)
          : dataDesconto.valorDesconto,
      cupomDescontoId: dataDesconto.id,
      observacao: '',
      cpf,
      telefone: mobileNumber,
      typeDeliverySelected: typeDelivery,
      pedidoItem: orderItems.map((item) => {
        return {
          item: item.id,
          TagRfid: item.tagRfid,
          Document: item.document,
          idProduto: item.productId,
          qtde: item.quantity,
          vlUnitario: item.unitPrice,
          desconto: 0,
          observacao: item.observation,
          grupoId: item.grupoId,
          subGrupoId: item.subGrupoId,
          pedidoSubItem: item.subItems.map((subItem) => {
            return {
              item: subItem.id,
              idPergunta: subItem.questionId,
              idPerguntaItemSelecionado: subItem.selectedSubItemId,
              qtde: subItem.quantity,
              observacao: '',
            };
          }),
        };
      }),
    };
    try {
      const response = await api.post('api/v1/Pedido', order, {
        withCredentials: true,
      });
      setLastOrder(response.data);
    } catch (error: any) {
      // setIsMsgBox(error.message);
      // setOpenIsMsgBox(true);
      if (
        error.response.data.MsgUsuario.includes(
          'O Item selecionado da Pergunta',
        ) ||
        error.response.data.MsgUsuario.includes('Favor selecionar no mínimo') ||
        error.response.data.MsgUsuario.includes('Favor selecionar no máximo')
      ) {
        setIsMsgBox(error.response.data.MsgUsuario);
        setOpenIsMsgBox(true);
      }
    }
  }

  return (
    <CheckoutContext.Provider
      value={{
        setNewLastOrder,
        lastOrder,
        orderItems,
        orderPrice,
        oldOrderPrice,
        setNewOrderItems,
        createOrder,
        setConnection,
        setTypeDelivery,
        typeDelivery,
        connection,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
}
