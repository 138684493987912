/* eslint-disable no-alert */
/* eslint-disable react/button-has-type */
import { useContext, useEffect } from 'react';
import { IoInformationCircleSharp } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import 'react-simple-keyboard/build/css/index.css';
import { CompanyContext } from '../../contexts/CompanyContext';
import { CompanyItem } from './CompanyItem';
import { Container, Grid } from './styles';

declare global {
  interface Window {
    bound: any;
  }
}

export function ChooseStore(): JSX.Element {
  const history = useHistory();
  const { companies, isModalLoginVisible } = useContext(CompanyContext);

  useEffect(() => {
    if (!navigator.onLine) history.push('/');
  });

  return (
    <>
      <Container>
        <div>
          <h1>Escolha seu estabelecimento:</h1>
          <section>
            <div>
              <span>Clique no</span>
              <IoInformationCircleSharp />
              <span>para mais informações!</span>
            </div>
          </section>
          <Grid isOdd={companies.length % 2 !== 0}>
            {companies.map((company) => (
              <CompanyItem key={company.id} company={company} />
            ))}
          </Grid>
        </div>
      </Container>
    </>
  );
}
