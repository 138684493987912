import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CheckoutContext } from '../../contexts/CheckoutContext';
import { CompanyContext } from '../../contexts/CompanyContext';
import { Container, Content } from './styles';

export function ChooseDelivery(): JSX.Element {
  const { loadPaymentMethods } = useContext(CompanyContext);
  const { setTypeDelivery } = useContext(CheckoutContext);

  const history = useHistory();

  async function handleLocalConsume() {
    await loadPaymentMethods();
    setTypeDelivery(1);
    history.push('/payment');
  }

  async function handlePickUpConsume() {
    await loadPaymentMethods();
    setTypeDelivery(2);
    history.push('/payment');
  }

  return (
    <Container>
      <Content>
        <div>
          <h1>Como deseja retirar o seu pedido?</h1>
        </div>
        <div>
          <button type="button" onClick={handleLocalConsume}>
            Comer no local
          </button>
          <button type="button" onClick={handlePickUpConsume}>
            Retirar no balcão
          </button>
        </div>
      </Content>
    </Container>
  );
}
