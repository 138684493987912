import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';

export const Screen = styled.div`
  background: #151414;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: #fff;
    font-size: 2rem;
  }

  .img-companies {
    width: 10rem;
    height: 10rem;
    border-radius: 1rem;
  }
  .companies-images {
    bottom: 20%;
    display: flex;
    justify-content: center;
  }

  .description-area {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem;
    text-align: center;
    margin-top: 10rem;
    gap: 2rem;
    .title-main {
      color: white;
      font-size: 5rem;
      display: flex;
      flex-direction: column;
      padding: 0 10rem;

      span {
        color: #facc15;
        font-size: 5rem;
        font-weight: bold;
        margin: 0 0 0 1rem;
      }
    }
    .title {
      color: white;
      font-size: 5rem;
    }

    .alertImage {
      width: 10rem;
      height: 10rem;
    }
  }

  .content {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-decoration: none;
    border: 0;

    .imgLogo {
      position: absolute;
      left: 0;
      top: 3%;
      align-self: flex-end;
      margin: 0 1.5rem 2.3rem;
      max-width: 12rem;
      z-index: 10;
      margin-top: -1.6rem;
    }

    div:last-child {
      position: relative;
      top: 50%;
      display: flex;
      justify-content: center;
      height: 40%;
      img {
        position: absolute;
        right: 0;
        align-self: flex-end;
        margin: 0 1.5rem 2.3rem;
        max-width: 12rem;
        margin-top: -1.6rem;

        @media only screen and (min-width: 1280px) {
          bottom: 25%;
        }
        @media only screen and (-webkit-min-device-pixel-ratio: 1.5) {
          top: 25%;
        }
      }

      h1 {
        position: absolute;
        right: 0;
        align-self: flex-end;
        margin: 0 3rem 2.3rem;
        max-width: 9rem;
        color: #fff;
        font: 1.2rem Heinberg, sans-serif;
        top: 75%;
      }
    }
  }

  @media only screen and (max-device-width: 1080px) {
    strong {
      font: 5rem Heinberg, sans-serif;
    }
  }
  @media only screen and (max-device-width: 768px) {
    strong {
      font: 4.5rem Heinberg, sans-serif;
    }
  }
  @media (orientation: landscape) {
    strong {
      font: 3.5rem Heinberg, sans-serif;
    }
    #comercial_connx {
      top: 0;
      margin-top: 0;
    }
  }
  @keyframes changeBackgroundColor {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 50;
    }
    40% {
      opacity: 100;
    }
  }
`;
