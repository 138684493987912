import { darken, lighten } from 'polished';
import Keyboard from 'react-simple-keyboard';
import styled, { css } from 'styled-components';
import tinycolor from 'tinycolor2';

export const ContainerModal = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 10;
  background-color: ${({ theme }) =>
    theme && tinycolor(theme.colors.Background).isDark()
      ? 'rgba(0, 0, 0, 0.6)'
      : 'rgba(0, 0, 0, 0.8)'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledKeyboard = styled(Keyboard)`
  padding: 0.5rem;
  margin: 0.25rem;
  background: #333;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
`;

interface IKeyboardContainer {
  isKeyboard: boolean;
}
export const NumberKeyboard = styled.div<IKeyboardContainer>`
  flex: 1;
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35%;
  display: ${({ isKeyboard }) => (isKeyboard ? 'flex' : 'none')};
  section {
    width: 100%;
    display: flex;

    & + section {
      margin-top: 1.5rem;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 5rem;
      min-width: 5rem;
      margin: 0 1rem;
      font-size: 3rem;
      border: 0;
      border-radius: 0.25rem;
      transition: transform 0.2s ease, box-shadow 0.2s ease;

      background: #f5f5f5;
      border: solid 1 ${({ theme }) => theme && theme.colors.DefaultColor};

      box-shadow: 1px 0px 0px
          ${({ theme }) => theme && theme.colors.DefaultColor},
        0px 1px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
        1px 1px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
        1px 1px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
        2px 1px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
        1px 2px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
        2px 2px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
        2px 2px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
        3px 2px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
        2px 3px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
        3px 3px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
        3px 3px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
        4px 3px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
        3px 4px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
        4px 4px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
        4px 4px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
        5px 4px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
        4px 5px 0px ${({ theme }) => theme && theme.colors.DefaultColor};

      &:active {
        transition: transform 0.2s ease, box-shadow 0.2s ease;

        transform: translateY(6px) translateX(6px);

        box-shadow: 0px 0px 0px
            ${({ theme }) => theme && theme.colors.DefaultColor},
          0px 0px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
          0px 0px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
          0px 0px 0px ${({ theme }) => theme && theme.colors.DefaultColor};

        &:active {
          transition: transform 0.2s ease, box-shadow 0.2s ease;

          transform: translateY(6px) translateX(6px);

          box-shadow: 0px 0px 0px
              ${({ theme }) => theme && theme.colors.DefaultColor},
            0px 0px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
            0px 0px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
            0px 0px 0px ${({ theme }) => theme && theme.colors.DefaultColor};
        }
      }

      &:last-child {
        button:last-child {
          flex: 1;
          max-width: none;
        }
      }
    }
  }
`;

export const KeyboardContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100vw;
`;

export const Container = styled.main`
  font-family: Poppins;
  overflow: auto;
  width: 80%;
  background: red;
  min-height: 35rem;
  overflow: auto;
  max-height: 85vh;
  width: 85vw;
  padding: 2.5rem 2rem;
  border-radius: 10px;
  border: 0;
  background-color: ${({ theme }) => theme && theme.colors.Background};
  animation-name: slide-bottom;
  margin-top: 4rem;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr;

  ${({ theme }) =>
    tinycolor(theme.colors.Background).isDark() &&
    css`
      border: 1px solid;
      border-color: ${theme.colors.SecondaryColor};
    `};

  h1 {
    font-weight: 500;
    font-size: 1.8rem;
    text-align: center;
    color: ${({ theme }) =>
      theme && tinycolor(theme.colors.Background).isLight()
        ? darken(0.9, theme.colors.Background)
        : lighten(0.9, theme.colors.Background)};
  }

  form {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    border-radius: 0.5rem;
    border: 2px solid #ececec;
    background: ${({ theme }) =>
      theme && tinycolor(theme.colors.Background).isLight()
        ? darken(0, theme.colors.Background)
        : lighten(1, theme.colors.Background)};

    input {
      flex: 1;
      border: 0;
      width: 100%;
      font-size: 2rem;
      padding: 1rem;
      color: #242424;
      background: #fff;
      transition: border 0.2s;
      background: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0, theme.colors.Background)
          : lighten(1, theme.colors.Background)};

      &::placeholder {
        color: ${({ theme }) =>
          theme && tinycolor(theme.colors.Background).isLight()
            ? '#242424'
            : '#fff'};
      }

      &:focus::placeholder {
        color: ${({ theme }) =>
          theme && tinycolor(theme.colors.Background).isLight()
            ? '#242424'
            : '#fff'};
      }
    }

    section {
      width: 100%;
      display: flex;

      & + section {
        margin-top: 1.5rem;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 5rem;
        min-width: 5rem;
        margin: 0 1rem;
        font-size: 3rem;
        border: 0;
        border-radius: 0.25rem;
        transition: transform 0.2s ease, box-shadow 0.2s ease;

        background: #f5f5f5;
        border: solid 1px ${({ theme }) => theme && theme.colors.DefaultColor};

        box-shadow: 1px 0px 0px
            ${({ theme }) => theme && theme.colors.DefaultColor},
          0px 1px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
          1px 1px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
          1px 1px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
          2px 1px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
          1px 2px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
          2px 2px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
          2px 2px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
          3px 2px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
          2px 3px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
          3px 3px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
          3px 3px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
          4px 3px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
          3px 4px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
          4px 4px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
          4px 4px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
          5px 4px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
          4px 5px 0px ${({ theme }) => theme && theme.colors.DefaultColor};

        &:active {
          transition: transform 0.2s ease, box-shadow 0.2s ease;

          transform: translateY(6px) translateX(6px);

          box-shadow: 0px 0px 0px
              ${({ theme }) => theme && theme.colors.DefaultColor},
            0px 0px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
            0px 0px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
            0px 0px 0px ${({ theme }) => theme && theme.colors.DefaultColor};
        }
      }

      &:last-child {
        button:last-child {
          flex: 1;
          max-width: none;
        }
      }
    }
  }

  footer {
    display: flex;
    margin-top: 3rem;

    button {
      flex: 1;
      padding: 1rem;
      border-radius: 0.5rem;
      border: 0;
      font-weight: 600;

      svg {
        color: ${({ theme }) => theme && theme.colors.SecondaryColor};
      }

      font-family: Poppins;
      font-size: 2rem;

      &:first-child {
        background: ${({ theme }) => theme && theme.colors.Background};
        margin-right: 1.25rem;
        color: ${({ theme }) => theme && theme.colors.DefaultColor};
        border: 2px solid ${({ theme }) => theme && theme.colors.DefaultColor};
      }

      &:last-child {
        margin-left: 1rem;
        background: ${({ theme }) => theme && theme.colors.DefaultColor};
        color: ${({ theme }) =>
          theme && tinycolor(theme.colors.DefaultColor).isLight()
            ? darken(0.9, '#ffffff')
            : lighten(0.9, '#ffffff')};
        border: 0;
      }
    }
  }
`;

export const ErrorMessage = styled.small`
  font-size: 1.5rem;
  font-weight: 500;
  color: #d92525;
`;
