import { darken, lighten } from 'polished';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

export const Container = styled.div`
  height: 100vh;
  background: ${({ theme }) => theme && theme.colors.Background};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Poppins;
  font-weight: 800;

  padding: 0 1rem;

  section {
    width: 20rem;
    height: 20rem;
    margin-top: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8%;

    img {
      padding: 0.35rem;
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 8%;
      background: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? 'trasparent'
          : lighten(0.9, theme.colors.Background)};
    }
  }

  h1 {
    margin-top: 5rem;

    font-size: 4rem;
    text-align: center;
    color: ${({ theme }) =>
      theme && tinycolor(theme.colors.Background).isLight()
        ? darken(0.9, theme.colors.Background)
        : lighten(0.9, theme.colors.Background)};
  }

  strong {
    margin-top: 7rem;

    font-size: 4rem;
    text-align: center;
    color: ${({ theme }) =>
      theme && tinycolor(theme.colors.Background).isLight()
        ? darken(0.9, theme.colors.Background)
        : lighten(0.9, theme.colors.Background)};
  }

  p {
    margin-top: 1.5rem;
    padding: 2rem 3rem;

    font-size: 8rem;
    font-family: Poppins;

    background: ${({ theme }) => theme && theme.colors.DefaultColor};
    border-radius: 3rem;
    color: #ffffff;
  }

  span {
    margin-top: 6rem;

    font-size: 4rem;
    font-weight: bold;
    text-align: center;
    color: ${({ theme }) => theme && theme.colors.DefaultColor};
  }
`;
