/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  // eslint-disable-next-line prettier/prettier
  useState
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from '../global/styles/theme';
import { ElectronService } from '../lib/electron.service';
import api from '../services/api';

interface CompanyContextData {
  loadCompanies: () => Promise<void>;
  companies: Company[];
  baseUrl: string;
  selectCompany: (id: number) => Promise<void>;
  selectedCompany: Company;
  loadPaymentMethods: () => Promise<void>;
  paymentMethods: PaymentMethod[];
  isOrderFinalized: boolean;
  setIsOrderFinalized: Dispatch<SetStateAction<boolean>>;
  isModalLoginVisible: boolean;
  setIsModalLoginVisible: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  needPayment: boolean;
  setNeedPayment: Dispatch<SetStateAction<boolean>>;
  totemName: string | undefined;
  setTotemName: Dispatch<SetStateAction<string | undefined>>;
  requestNumberMobile: boolean;
  setRequestNumberMobile: (e: boolean) => void;

  isOpenMsgBox: boolean;
  isMsgBox: string;
  setOpenIsMsgBox: (e: boolean) => void;
  setIsMsgBox: (e: string) => void;

  totemNeedPayment: boolean;
  setTotemNeedPayment: (e: boolean) => void;

  totemID: string | undefined;
  setTotemID: Dispatch<SetStateAction<string | undefined>>;

  isAdminEmpresa: boolean;
  setIsAdminEmpresa: (e: boolean) => void;

  serviceElectron: ElectronService;

  linkLoading: string;
  setLinkLoading: (e: string) => void;

  readOnlyMenu: boolean;

  visivelCar: boolean;
  setVisivelCar: (e: boolean) => void;

  totemMaintenance: boolean;
  setTotemMaintenance: (e: boolean) => void;
}

interface CompanyProviderProps {
  children: ReactNode;
}

export interface Company {
  id: number;
  nome: string;
  descricao: string;
  ativo: boolean;
  logotipo: string;
  plataformaSistemica: number;
  empresaPagamento: number;
  solicitaPagto: boolean;
  aberto: boolean;
  somenteCardapioTotem: boolean;
  validaNivelPapelImpressora: boolean;
  solicitaTelefone: boolean;
  solicitaEmailCpf: boolean;
  loadingLink: string;
  cnpjEstabelecimento: string;
  urlPWA: string;
  primaryColor: string;
  secondaryColor: string;
  darkTheme: boolean;
  typeDelivery: number;
  habilitarNFCe: boolean;
}

interface PaymentMethod {
  id: number;
  formaPagamento: number;
  empresaPagto: number;
  plataformaSistemica: number;
  descricao: string;
  qtdVezesParcelamento: number;
  pagamentoAVista: boolean;
}

export interface Theme {
  colors: colors;
}

interface colors {
  DefaultColor: string;
  SecondaryColor: string;

  Background: string;
}

export const CompanyContext = createContext({} as CompanyContextData);

window.bound = window.bound || {};

export function CompanyProvider({
  children,
}: CompanyProviderProps): JSX.Element {
  const baseUrl = 'https://touch-img.s3.sa-east-1.amazonaws.com/app-images/';
  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedCompany, setSelectedCompany] = useState({} as Company);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [isOrderFinalized, setIsOrderFinalized] = useState(true);
  const [isModalLoginVisible, setIsModalLoginVisible] = useState(false);
  const [linkLoading, setLinkLoading] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenMsgBox, setOpenIsMsgBox] = useState(false);
  const [isMsgBox, setIsMsgBox] = useState('');
  const [needPayment, setNeedPayment] = useState(true);
  const [totemNeedPayment, setTotemNeedPayment] = useState(true);
  const [requestNumberMobile, setRequestNumberMobile] = useState(true);
  const [newTheme, setNewTheme] = useState(theme);
  const [totemName, setTotemName] = useState<string | undefined>('');
  const [totemID, setTotemID] = useState<string | undefined>('');
  const [totemMaintenance, setTotemMaintenance] = useState(false);
  const [isAdminEmpresa, setIsAdminEmpresa] = useState(false);
  const [readOnlyMenu, setReadOnlyMenu] = useState(false);
  const [visivelCar, setVisivelCar] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const serviceElectron = new ElectronService();

  useEffect(() => {
    async function IsloadingApi() {
      const resp = await serviceElectron.ipcRenderer?.invoke('getAPI');
      if (resp.Sucesso) api.defaults.baseURL = resp.Msg;
    }
    if (serviceElectron?.isElectronApp) IsloadingApi();
  }, [serviceElectron?.isElectronApp]);

  useEffect(() => {
    if (!navigator.onLine) {
      history.push('/');
      return;
    }

    const validPaths = ['/', '/success', '/tefdestaxa', '/maintenance'];
    const currentPath = history.location.pathname;
    console.log(currentPath);
    if (validPaths.includes(currentPath)) return;

    let timeOut = 5;
    if (currentPath === '/store') timeOut = 2;

    let inactiveTimeout: number;

    const events = ['click', 'scroll', 'keypress', 'touchstart'];
    const goBack = () => {
      history.push('/');
    };
    const setTimeouts = () => {
      inactiveTimeout = setTimeout(goBack, 1000 * 60 * timeOut);
    };

    const clearTimeouts = () => {
      if (inactiveTimeout) clearTimeout(inactiveTimeout);
    };

    const resetTimeout = () => {
      clearTimeouts();
      setTimeouts();
    };

    events.forEach((event) => {
      window.addEventListener(event, resetTimeout);
    });

    setTimeouts();

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, clearTimeouts);
      });
    };
  }, [history.location.pathname]);

  async function loadCompanies() {
    if (isModalLoginVisible) return;
    try {
      setIsLoading(true);
      const response = await api.get('api/v1/Empresa/GetAllEmpresaAtiva');
      const responseCompanies = response.data;
      if (responseCompanies.length === 0 && totemMaintenance === false)
        history.push('/');
      // eslint-disable-next-line no-restricted-syntax
      for (const company of response.data) {
        const index = responseCompanies.findIndex(
          (x: any) => x.id === company.id,
        );
        if (company.logotipo !== null && company.logotipo !== '') {
          let logo = `${baseUrl}${company.logotipo}`;
          if (Object.keys(window.bound).length !== 0) {
            // eslint-disable-next-line no-await-in-loop
            const responseSmart = await window.bound.getImage(company.logotipo);
            if (responseSmart.Sucesso) {
              logo = responseSmart.Msg;
            }
          } else if (serviceElectron?.isElectronApp) {
            // eslint-disable-next-line no-await-in-loop
            const responseSmart = await serviceElectron?.ipcRenderer?.invoke(
              'getImage',
              company.logotipo,
            );
            if (responseSmart.Sucesso) {
              logo = responseSmart.Msg;
            }
          }
          responseCompanies[index].logotipo = logo;
        }
      }
      setCompanies(responseCompanies);
      if (
        totemMaintenance === false ||
        history.location.pathname !== '/maintenance'
      ) {
        if ((responseCompanies || []).length === 1)
          await selectCompany(responseCompanies[0].id, responseCompanies[0]);
        else
          history.push({
            pathname: '/stores',
            state: '/',
          });
      }
    } catch (error) {
      if (location.pathname !== '/') history.push('/');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (totemMaintenance && history.location.pathname !== '/maintenance')
      history.push('/maintenance');
    else if (!totemMaintenance && history.location.pathname === '/maintenance')
      history.push('/');
  }, [totemMaintenance]);

  useEffect(() => {
    if (!isOrderFinalized) return;

    setVisivelCar(false);
    setIsLoading(false);
    setCompanies([]);
    setSelectedCompany({} as Company);
    setPaymentMethods([]);
  }, [isOrderFinalized]);

  async function selectCompany(
    idCompany: number,
    companyNew?: Company,
  ): Promise<void> {
    let [company] = companies.filter((item) => item.id === idCompany);
    if (companyNew) {
      company = companyNew;
      idCompany = companyNew.id;
    }
    localStorage.setItem('@Totem:company', JSON.stringify(company));
    setSelectedCompany(company);
    setNeedPayment(company.solicitaPagto && totemNeedPayment);
    setReadOnlyMenu(company.somenteCardapioTotem || !company.aberto);

    setNewTheme({
      colors: {
        DefaultColor: company?.primaryColor || '#0496FF',
        SecondaryColor: company?.secondaryColor || '#99c9FF',
        Background: company.darkTheme ? '#000' : '#fff',
      },
    });
    history.push({
      pathname: '/menu',
      state: '/',
    });
  }

  async function loadPaymentMethods() {
    try {
      const response = await api.get(
        `api/v1/FormaPagamentoEmpresa/GetPagamentoAtivo/${selectedCompany.id}`,
      );

      setPaymentMethods(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <CompanyContext.Provider
      value={{
        loadCompanies,
        companies,
        baseUrl,
        selectCompany,
        selectedCompany,
        loadPaymentMethods,
        paymentMethods,
        isOrderFinalized,
        setIsOrderFinalized,
        isModalLoginVisible,
        setIsModalLoginVisible,
        isLoading,
        setIsLoading,
        needPayment,
        setNeedPayment,
        totemName,
        setTotemName,
        requestNumberMobile,
        setRequestNumberMobile,
        isOpenMsgBox,
        isMsgBox,
        setOpenIsMsgBox,
        setIsMsgBox,
        totemNeedPayment,
        setTotemNeedPayment,
        totemID,
        setTotemID,
        isAdminEmpresa,
        setIsAdminEmpresa,
        serviceElectron,
        linkLoading,
        setLinkLoading,
        readOnlyMenu,
        visivelCar,
        setVisivelCar,
        totemMaintenance,
        setTotemMaintenance,
      }}
    >
      <ThemeProvider theme={newTheme}>{children}</ThemeProvider>
    </CompanyContext.Provider>
  );
}
