import { darken, lighten } from 'polished';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

export const Container = styled.main`
  background-color: ${({ theme }) => theme && theme.colors.Background};
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  height: 100%;
  max-width: 80%;
  display: flex;
  gap: 10rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Heinberg;

  button {
    margin-top: 1.5rem;
    width: 100%;
    font-weight: 600;
    display: flex;
    justify-content: center;
    padding: 2rem;
    border: 0;
    border-radius: 1.5rem;
    color: ${({ theme }) => theme && theme.colors.DefaultColor};
    background: transparent;
    border: 2px solid ${({ theme }) => theme && theme.colors.DefaultColor};

    font-size: 4rem;
    font-family: Poppins;
    :disabled {
      filter: opacity(0.5);
      cursor: not-allowed;
    }

    :hover {
      background: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0.1, theme.colors.SecondaryColor)
          : lighten(0.1, theme.colors.SecondaryColor)};
    }
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    text-align: center;
    h1 {
      font-size: 5rem;
      font-weight: 700;
      color: ${({ theme }) => theme && theme.colors.DefaultColor};
      margin-top: 2rem;

      @media screen and (max-width: 768px) {
        font-size: 4.5rem;
      }
    }
  }
`;
